<template>
  <div>
    <v-container fluid style="padding-bottom: 10vh;">
      <div v-if="!user.data">
        <Menubar />
      </div>
      <div v-if="user.data">
        <UserMenu
          v-if="user.data.rolle == 'Trainer' || user.data.rolle == 'Vorstand'"
        />
        <ZuschauerMenu v-if="user.data.rolle == 'Zuschauer'" />
        <SchiriMenu v-if="user.data.rolle == 'Schiedsrichter'" />
      </div>
      <v-main v-if="!template.datenschutz">
        <v-row justify="center" v-if="template.var.verband == 'FLVW'">
          <v-col cols="12" md="8" lg="6">
            <v-row justify="center">
              <v-col cols="12">
                <h1>Datenschutzinformation gemäß Art. 13, 14 DSGVO</h1>
                <span>
                  Der jeweilige Verein bzw. (Landes-)Verband („Verein“) ist
                  gemäß der jeweiligen CORONA- Schutzverordnung des jeweiligen
                  Bundeslandes zum Schutz vor Neuinfizierungen mit dem
                  Coronavirus SARS-CoV-2 („Coronaschutzverordnung –
                  CoronaSchVO“) verpflichtet, ggf. mit Einverständnis der
                  Personen, die an Veranstaltungen/Angeboten des Vereins
                  teilnehmen, deren Name, Adresse und Telefonnummer, Zeitraum
                  des Aufenthalts bzw. Zeitpunkt von An- und Abreise schriftlich
                  zu erfassen und diese Daten zum Zwecke der
                  Kontaktnachverfolgung für vier (4) Wochen aufzubewahren. Um
                  eine gesonderte Erfassung in jedem einzelnen Fall zu
                  erleichtern, nutzt der Verein für diese Datenerhebungen diese
                  von der FLVW Marketing GmbH zur Verfügung gestellte
                  FLVW-CheckIn Web-App für Vereine („CheckIn-App“).
                </span>
                <h2 class="mt-4">1. Verantwortlicher</h2>
                <span>
                  Verantwortlicher für den Datenschutz gem. Art. 4 Nr. 7
                  Datenschutz-Grundverordnung (DSGVO) für die Nutzung der
                  FLVW-App, damit auch für die Registrierung und Eingabe von
                  Vereinsdaten in der CheckIn-App ist der jeweilige
                  (Landes-)Verband, an den die einzelnen Vereine qua
                  Mitgliedschaft satzungsrechtlich angebunden sind.
                  <br />
                  <br />
                  Soweit der (Landes-)Verband Daten im Rahmen der Nutzung der
                  CheckIn-App erhebt und speichert, ist dieser allein
                  Verantwortlicher. Die FLVW Marketing GmbH ist in diesem
                  Zusammenhang Auftragsverarbeiter iSd Art. 28 DSGVO.
                </span>

                <h2 class="mt-4">
                  2. Kontaktdaten des Datenschutzbeauftragten
                </h2>
                <span>
                  Die FLVW Marketing GmbH hat im Zusammenhang mit der
                  Datenverarbeitung der CheckIn-App einen Datenschutzbeauftragen
                  bestellt. Sofern Sie Fragen rund um die CheckIn-App haben,
                  können Sie den Datenschutzbeauftragten der FLVW Marketing GmbH
                  kontaktieren unter datenschutz@flvw- marketing.de.
                  <br />
                  <br />
                  Sofern Sie sonstige Fragen zum Datenschutz haben, die nicht im
                  Zusammenhang mit der CheckIn-App stehen, kontaktieren Sie
                  bitte den Datenschutzbeauftragten des jeweiligen Vereins bzw.
                  des jeweiligen Landesverbandes.
                </span>
                <h2 class="mt-4">3. Informationen zur Datenverarbeitung</h2>
                <br />
                <span>
                  <b>a) Download und Nutzung der FLVW-App</b>
                  <br />
                  Bei der bloßen Nutzung der Web-App, ohne Registrierung oder
                  der Übermittlung anderweitiger Informationen, erheben wir nur
                  die personenbezogenen Daten, die der eigene Browser an unseren
                  Server übermittelt. Diese Daten sind technisch erforderlich,
                  um die Web-App anzuzeigen und die Stabilität und Sicherheit zu
                  gewährleisten. Diese Informationen werden temporär in einem
                  sog. Logfile bis zur automatisierten Löschung nach 28 Tagen
                  gespeichert:
                  <br />
                  <ul>
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit der Anfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Jeweils übertragene Datenmenge</li>
                  </ul>
                  <br />
                  Die IP-Adressen der Nutzer unserer Web-App werden zu
                  Sicherheits- und Überwachungszwecken aufgezeichnet. Damit soll
                  auch ein Missbrauch unseres Dienstes verhindert werden. Ohne
                  eine solche Speicherung kann der Erstellung von Fake-Profilen,
                  der Verbreitung von Spam, Flooding, Phishing oder Viren nicht
                  ausreichend entgegenwirken. Zudem dient die Speicherung der
                  Sicherheit von (insbesondere jugendlichen) Nutzern, für
                  vorbeugende Maßnahmen gegen zukünftige Straftaten und für eine
                  mögliche Verfolgbarkeit von über unseren Dienst begangene
                  Straftaten.
                  <br />
                  <br />
                  Die Rechtsgrundlagen für diese Datenverarbeitungen ergeben
                  sich aus Artikel 6 Abs. 1 Satz 1 lit. b) und lit. f) DSGVO.
                </span>
                <br />
                <br />
                <span>
                  <b>
                    b) Bei Abfragen der Daten durch den Verein und Speicherung
                    in der FLVW-App
                  </b>
                  <br />
                  Wenn Sie an einer Veranstaltung des Vereins teilnehmen oder
                  die Angebote des Vereins nutzen, erhebt der Verein von Ihnen
                  die folgenden Daten und speichert diese mittels der FLVW-App:
                  <br />
                  <ul>
                    <li>Name,</li>
                    <li>Adresse,</li>
                    <li>Telefonnummer oder E-Mail-Adresse,</li>
                    <li>
                      Zeitraum des Aufenthalts bzw. Zeitpunkt von An- und
                      Abreise.
                    </li>
                  </ul>
                  <br />
                  Diese Daten werden in der FLVW-App für eine Dauer von vier (4)
                  Wochen gespeichert und anschließend automatisch gelöscht,
                  sofern kein Corona-Infektions- und/oder Verdachtsfall im
                  Rahmen der betroffenen Veranstaltung aufgetreten ist oder eine
                  anderweitige gesetzliche Verpflichtung eine längere
                  Speicherung vorsieht.
                  <br />
                  <br />
                  Die Rechtsgrundlage für diese Datenverarbeitung ist die
                  Erfüllung der gesetzlichen Verpflichtungen gemäß Artikel 6
                  Abs. 1 Satz 1 lit. c DSGVO in Verbindung mit der jeweiligen
                  CoronaSchVO wozu der (Landes-)Verband bzw. der Verein
                  verpflichtet ist (ggf. auch lit. d zum Schutz lebenswichtiger
                  Interessen) und die berechtigten Interessen des
                  (Landes-)Verbands (zur Aufrechterhaltung des Trainings- und
                  Spielbetriebs sowie Schutz der Gesundheit aller Beteiligten
                  zum Zwecke der Kontaktnachverfolgung).
                </span>
                <br />
                <br />
                <span>
                  <b>c) Bei Anlegen eines Accounts in der FLVW-App</b>
                  <br />
                  Wenn Sie einen Account in der FLVW-App anlegen, werden Sie
                  aufgefordert, die folgenden Daten einzugeben:
                  <br />
                  <ul>
                    <li>Name,</li>
                    <li>Adresse,</li>
                    <li>Telefonnummer oder E-Mail-Adresse.</li>
                  </ul>
                  <br />
                  Wenn Sie an einer Veranstaltung des Vereins teilnehmen oder
                  die Angebote des Vereins nutzen, werden Sie oder der
                  zuständige Trainer/Vereinsvorstand aufgefordert, in Ihren
                  FLVW-App Account zusätzlich die folgenden Daten eintragen:
                  <br />
                  <ul>
                    <li>
                      Zeitraum des Aufenthalts bzw. Zeitpunkt von An- und
                      Abreise
                    </li>
                  </ul>
                  <br />
                  Die oben angegeben Daten werden solange gespeichert, bis Sie
                  den persönlichen FLVW-Account vollständig löschen, mindestens
                  aber für eine Dauer von vier (4) Wochen, sofern kein Corona-
                  Infektions- und/oder Verdachtsfall im Rahmen der betroffenen
                  Veranstaltung aufgetreten ist oder eine anderweitige
                  gesetzliche Verpflichtung eine längere Speicherung vorsieht.
                  Die Löschung des Vereinsaccounts kann nur durch den jeweils
                  rechtlichen Vertreter des Vereins erfolgen. Hierzu schicken
                  Sie bitte einen Löschungsantrag mit Unterschrift des
                  rechtlichen Vertreters per Fax (02307 92492-22) oder
                  postalisch an die folgende Anschrift:
                  <br />
                  <br />
                  FLVW Marketing GmbH, Jahnstraße 76-78, 59174 Kamen
                  <br />
                  <br />
                  Die Rechtsgrundlage für diese Datenverarbeitung ist die
                  Erfüllung der gesetzlichen Verpflichtungen gemäß Artikel 6
                  Abs. 1 Satz 1 lit. c DSGVO in Verbindung mit der jeweiligen
                  CoronaSchVO wozu der (Landes-)Verband bzw. der Verein
                  verpflichtet ist (ggf. auch lit. d zum Schutz lebenswichtiger
                  Interessen) und die berechtigten Interessen des
                  (Landes-)Verbands (zur Aufrechterhaltung des Trainings- und
                  Spielbetriebs sowie Schutz der Gesundheit aller Beteiligten
                  zum Zwecke der Kontaktnachverfolgung).
                </span>

                <h2 class="mt-4">
                  4. Weitergabe der Daten an Dritte/Speicherort
                </h2>
                <span>
                  Eine Weitergabe von Daten an Dritte erfolgt nur im Rahmen der
                  gesetzlichen Vorgaben. Der Verein, welcher für den Upload der
                  vereinseigenen Daten zuständig ist, gibt Daten an Dritte nur
                  dann weiter, wenn dies zur Erfüllung der
                  Corona-Schutzverordnung nötig ist.
                  <br />
                  <br />
                  Die in der FLVW-App gesammelten Daten werden auf der Google
                  Cloud Plattform auf Grundlage einer
                  Auftragsverarbeitungsvereinbarung gespeichert und verarbeitet
                  (Art. 28 DSGVO). Der Serverstandort des Auftragsverarbeiters
                  befindet sich innerhalb der EU; eine Übermittlung der Daten in
                  Drittstatten erfolgt nicht.
                </span>
                <h2 class="mt-4">5. Sicherheitsmaßnahmen</h2>
                <span>
                  Die FLVW Marketing GmbH trifft als Auftragsverarbeiter
                  organisatorische, vertragliche und technische
                  Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um
                  sicherzustellen, dass die Vorschriften der Datenschutzgesetze
                  eingehalten werden und um damit die gespeicherten Daten gegen
                  zufällige oder vorsätzliche Manipulationen, Verlust,
                  Zerstörung oder gegen den Zugriff unberechtigter Personen zu
                  schützen. Der Verein ist für die Sicherstellung der Anlegung
                  von korrekten Vereinsprofilen berechtigt und stellt sicher,
                  dass nur Daten der Vereinsmitglieder und ehrenamtlichen
                  Mitarbeitern (z.B. Trainern) importiert werden.
                  <br />
                  <br />
                  Zu den Sicherheitsmaßnahmen gehört insbesondere die
                  verschlüsselte Übertragung von Daten zwischen dem eigenen
                  Browser und dem Server sowie die Verarbeitung der Daten auf
                  deutschen Servern.
                </span>
                <h2 class="mt-4">6. Rechte</h2>
                <span>
                  Jede Person und jeder Verein hat der FLVW Marketing GmbH
                  gegenüber folgende Rechte hinsichtlich der sie betreffenden
                  personenbezogenen Daten:
                  <br />
                  <ul>
                    <li>Auskunftsrecht (Art. 15 DSGVO)</li>
                    <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
                    <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
                    <li>Recht auf Löschung (Art. 17 DSGVO)</li>
                    <li>
                      Recht auf Einschränkung der Verarbeitung (Art. 18 f.
                      DSGVO)
                    </li>
                    <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
                  </ul>
                  <br />
                  Zudem hat jede Person das Recht, sich bei einer
                  Datenschutz-Aufsichtsbehörde über die Verarbeitung seiner
                  personenbezogenen Daten durch die FLVW Marketing GmbH zu
                  beschweren. Dieselben Rechte hat auch jede Person dem Verein
                  gegenüber, der im Rahmen seiner Veranstaltungen/Angebote die
                  personenbezogenen Daten erhebt.
                  <br />
                  <br />

                  Zur Geltendmachung ihrer Rechte können Sie sich jederzeit an
                  datenschutz@flvw-marketing.de wenden.
                  <br />
                  <br />
                  Stand: April 2021
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="template.var.verband == 'BFV'">
          <v-col cols="12" md="8" lg="6">
            <v-row justify="center">
              <v-col cols="12">
                <h1>Datenschutzinformation gemäß Art. 13, 14 DSGVO</h1>
                <span>
                  Der jeweilige Verein bzw. (Landes-)Verband („Verein“) ist
                  gemäß der jeweiligen CORONA- Schutzverordnung des jeweiligen
                  Bundeslandes zum Schutz vor Neuinfizierungen mit dem
                  Coronavirus SARS-CoV-2 (SARS-CoV-2-Infektionsschutzmaßnahmenverordnung in der aktuellen Fassung; abrufbar über folgenden Link:
                  https://www.berlin.de/corona/massnahmen/verordnung/)
                  verpflichtet, ggf. mit Einverständnis der Personen, die an
                  Veranstaltungen/Angeboten des Vereins teilnehmen, deren Name,
                  Adresse und Telefonnummer, Zeitraum des Aufenthalts bzw.
                  Zeitpunkt von An- und Abreise schriftlich zu erfassen und
                  diese Daten zum Zwecke der Kontaktnachverfolgung für vier (4)
                  Wochen aufzubewahren. Um eine gesonderte Erfassung in jedem
                  einzelnen Fall zu erleichtern, nutzt der Verein für diese
                  Datenerhebungen diese von der FLVW Marketing GmbH zur
                  Verfügung gestellte FLVW-CheckIn Web-App für Vereine („BFV
                  Service-App“).
                </span>
                <h2 class="mt-4">1. Verantwortlicher</h2>
                <span>
                  Verantwortlicher für den Datenschutz gem. Art. 4 Nr. 7
                  Datenschutz-Grundverordnung (DSGVO) für die Nutzung der BFV
                  Service-App, damit auch für die Registrierung und Eingabe von
                  Vereinsdaten in der BFV Service-App ist der jeweilige
                  (Landes-)Verband, an den die einzelnen Vereine qua
                  Mitgliedschaft satzungsrechtlich angebunden sind. Dabei
                  handelt es sich um:
                  <br />
                  <br />
                  Berliner Fußball-Verband e.V.
                  <br />
                  Humboldtstraße 8a
                  <br />
                  14193 Berlin
                  <br />
                  <br />
                  Tel: 030 / 89 69 94-0
                  <br />
                  Fax: 030 / 89 69 94-101
                  <br />
                  info@berliner-fussball.de
                  <br />
                  <br />
                  <br />
                  Soweit der (Landes-)Verband Daten im Rahmen der Nutzung der
                  BFV Service-App erhebt und speichert, ist dieser allein
                  Verantwortlicher. Die FLVW Marketing GmbH ist in diesem
                  Zusammenhang Auftragsverarbeiter i.S.d. Art. 28 DSGVO.
                </span>

                <h2 class="mt-4">
                  2. Kontaktdaten des Datenschutzbeauftragten
                </h2>
                <span>
                  Der Berliner Fußballverband e.V. hat einen
                  Datenschutzbeauftragten benannt:
                  <br />
                  <br />
                  LOROP GmbH
                  <br />
                  Dennis Schulz
                  <br />
                  Landgrafenstr. 16,
                  <br />
                  10787 Berlin
                  <br />
                  <br />
                  Tel: 030 3309626-27
                  <br />
                  Fax: 030 3309626-29
                  <br />
                  datenschutz@lorop.de
                  <br />
                  <br />
                  <br />
                  Diesen können Sie jederzeit bzgl. der Verarbeitung Ihrer
                  personenbezogenen Daten durch den Berliner Fußballverband e.V.
                  kontaktieren, insbesondere auch bzgl. Rückfragen zur BFV
                  Service-App.
                </span>
                <h2 class="mt-4">3. Informationen zur Datenverarbeitung</h2>
                <br />
                <span>
                  <b>a) Download und Nutzung der BFV Service-App</b>
                  <br />
                  Bei der bloßen Nutzung der Web-App, ohne Registrierung oder
                  der Übermittlung anderweitiger Informationen, erheben wir nur
                  die personenbezogenen Daten, die der eigene Browser an unseren
                  Server übermittelt. Diese Daten sind technisch erforderlich,
                  um die Web-App anzuzeigen und die Stabilität und Sicherheit zu
                  gewährleisten. Diese Informationen werden temporär in einem
                  sog. Logfile bis zur automatisierten Löschung nach 28 Tagen
                  gespeichert:
                  <br />
                  <ul>
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit der Anfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Jeweils übertragene Datenmenge</li>
                  </ul>
                  <br />
                  Die IP-Adressen der Nutzer unserer Web-App werden zu
                  Sicherheits- und Überwachungszwecken aufgezeichnet. Damit soll
                  auch ein Missbrauch unseres Dienstes verhindert werden. Ohne
                  eine solche Speicherung kann der Erstellung von Fake-Profilen,
                  der Verbreitung von Spam, Flooding, Phishing oder Viren nicht
                  ausreichend entgegenwirken. Zudem dient die Speicherung der
                  Sicherheit von (insbesondere jugendlichen) Nutzern, für
                  vorbeugende Maßnahmen gegen zukünftige Straftaten und für eine
                  mögliche Verfolgbarkeit von über unseren Dienst begangene
                  Straftaten.
                  <br />
                  <br />
                  Die Rechtsgrundlagen für diese Datenverarbeitungen ergeben
                  sich aus Artikel 6 Abs. 1 Satz 1 lit. b) und lit. f) DSGVO.
                </span>
                <br />
                <br />
                <span>
                  <b>
                    b) Bei Abfragen der Daten durch den Verein und Speicherung
                    in der BFV Service-App
                  </b>
                  <br />
                  Wenn Sie an einer Veranstaltung des Vereins teilnehmen oder
                  die Angebote des Vereins nutzen, erhebt der Verein von Ihnen
                  die folgenden Daten und speichert diese mittels der BFV
                  Service-App:
                  <br />
                  <ul>
                    <li>Name,</li>
                    <li>Adresse,</li>
                    <li>Telefonnummer oder E-Mail-Adresse,</li>
                    <li>
                      Zeitraum des Aufenthalts bzw. Zeitpunkt von An- und
                      Abreise.
                    </li>
                  </ul>
                  <br />
                  Diese Daten werden in der BFV Service-App für eine Dauer von
                  vier (4) Wochen gespeichert und anschließend automatisch
                  gelöscht, sofern kein Corona-Infektions- und/oder
                  Verdachtsfall im Rahmen der betroffenen Veranstaltung
                  aufgetreten ist oder eine anderweitige gesetzliche
                  Verpflichtung eine längere Speicherung vorsieht.
                  <br />
                  <br />
                  Die Rechtsgrundlage für diese Datenverarbeitung ist die
                  Erfüllung der gesetzlichen Verpflichtungen gemäß Artikel 6
                  Abs. 1 Satz 1 lit. c DSGVO in Verbindung mit der jeweiligen
                  CoronaSchVO wozu der (Landes-)Verband bzw. der Verein
                  verpflichtet ist (ggf. auch lit. d zum Schutz lebenswichtiger
                  Interessen) und die berechtigten Interessen des
                  (Landes-)Verbands (zur Aufrechterhaltung des Trainings- und
                  Spielbetriebs sowie Schutz der Gesundheit aller Beteiligten
                  zum Zwecke der Kontaktnachverfolgung).
                </span>
                <br />
                <br />
                <span>
                  <b>c) Bei Anlegen eines Accounts in der BFV Service-App</b>
                  <br />
                  Wenn Sie einen Account in der BFV Service-App anlegen, werden
                  Sie aufgefordert, die folgenden Daten einzugeben:
                  <br />
                  <ul>
                    <li>Name,</li>
                    <li>Adresse,</li>
                    <li>Telefonnummer oder E-Mail-Adresse.</li>
                  </ul>
                  <br />
                  Wenn Sie an einer Veranstaltung des Vereins teilnehmen oder
                  die Angebote des Vereins nutzen, werden Sie oder der
                  zuständige Trainer/Vereinsvorstand aufgefordert, in Ihren
                  BFV-Service-App Account zusätzlich die folgenden Daten
                  eintragen:
                  <br />
                  <ul>
                    <li>
                      Zeitraum des Aufenthalts bzw. Zeitpunkt von An- und
                      Abreise
                    </li>
                  </ul>
                  <br />
                  Die oben angegeben Daten werden solange gespeichert, bis Sie
                  den persönlichen BFV Service-App-Account vollständig löschen,
                  mindestens aber für eine Dauer von vier (4) Wochen, sofern
                  kein Corona- Infektions- und/oder Verdachtsfall im Rahmen der
                  betroffenen Veranstaltung aufgetreten ist oder eine
                  anderweitige gesetzliche Verpflichtung eine längere
                  Speicherung vorsieht. Die Rechtsgrundlage für diese
                  Datenverarbeitung ist die Erfüllung der gesetzlichen
                  Verpflichtungen gemäß Artikel 6 Abs. 1 Satz 1 lit. c DSGVO in
                  Verbindung mit der zweiten SARS-CoV-2-
                  Infektionsschutzmaßnahmenverordnung wozu der (Landes-)Verband
                  bzw. der Verein verpflichtet ist (ggf. auch lit. d zum Schutz
                  lebenswichtiger Interessen) und die berechtigten Interessen
                  des (Landes- )Verbands (zur Aufrechterhaltung des Trainings-
                  und Spielbetriebs sowie Schutz der Gesundheit aller
                  Beteiligten zum Zwecke der Kontaktnachverfolgung).
                </span>

                <h2 class="mt-4">
                  4. Weitergabe der Daten an Dritte/Speicherort
                </h2>
                <span>
                  Eine Weitergabe von Daten an Dritte erfolgt nur im Rahmen der
                  gesetzlichen Vorgaben. Der Verein, welcher für den Upload der
                  vereinseigenen Daten zuständig ist, gibt Daten an Dritte nur
                  dann weiter, wenn dies zur Erfüllung der
                  Corona-Schutzverordnung nötig ist.
                  <br />
                  <br />
                  Die in der BFV Service-App gesammelten Daten werden auf der
                  Google Cloud Plattform auf Grundlage einer
                  Auftragsverarbeitungsvereinbarung gespeichert und verarbeitet
                  (Art. 28 DSGVO). Der Serverstandort des Auftragsverarbeiters
                  befindet sich innerhalb der EU; eine Übermittlung der Daten in
                  Drittstatten erfolgt nicht.
                </span>

                <h2 class="mt-4">5. Sicherheitsmaßnahmen</h2>
                <span>
                  Der Berliner Fußball-Verband e. V. trifft als Verantwortlicher
                  geeignete technische und organisatorische Maßnahmen und hat in
                  diesem Zusammenhang einen Auftragsverarbeitungsvertrag mit der
                  FLVW Marketing GmbH abgeschlossen, um die Sicherheit der
                  Verarbeitung bei der Nutzung der BFV Service App zu
                  gewährleisten. Der Verein ist für die Sicherstellung der
                  Anlegung von korrekten Vereinsprofilen zuständig und stellt
                  sicher, dass nur Daten der Vereinsmitglieder und
                  ehrenamtlichen Mitarbeitern (z.B. Trainern) importiert werden.
                  <br />
                  Zu den Sicherheitsmaßnahmen gehört insbesondere die
                  verschlüsselte Übertragung von Daten zwischen dem eigenen
                  Browser und dem Server sowie die Verarbeitung der Daten auf
                  deutschen Servern.
                  <br />
                  Die FLVW Marketing GmbH trifft als Auftragsverarbeiter
                  organisatorische, vertragliche und technische
                  Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um
                  sicherzustellen, dass die Vorschriften der Datenschutzgesetze
                  eingehalten werden und um damit die gespeicherten Daten gegen
                  zufällige oder vorsätzliche Manipulationen, Verlust,
                  Zerstörung oder gegen den Zugriff unberechtigter Personen zu
                  schützen.
                </span>
                <h2 class="mt-4">6. Rechte</h2>
                <span>
                  Jede Person und jeder Verein hat dem Berliner Fußball-Verband
                  e.V. gegenüber folgende Rechte hinsichtlich der sie
                  betreffenden personenbezogenen Daten:
                  <br />
                  <ul>
                    <li>Auskunftsrecht (Art. 15 DSGVO)</li>
                    <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
                    <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
                    <li>Recht auf Löschung (Art. 17 DSGVO)</li>
                    <li>
                      Recht auf Einschränkung der Verarbeitung (Art. 18 f.
                      DSGVO)
                    </li>
                    <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
                  </ul>
                  <br />
                  Zudem hat jede Person das Recht, sich bei einer
                  Datenschutz-Aufsichtsbehörde über die Verarbeitung seiner
                  personenbezogenen Daten zu beschweren.
                  <br />
                  <br />
                  Stand: April 2021
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>

<script>
import Menubar from '../components/public/nav'
import UserMenu from '../components/nav/nav'
import ZuschauerMenu from '../components/zuschauer/nav'
import SchiriMenu from '../components/schiedsrichter/nav'
import { mapGetters } from 'vuex'

export default {
  name: 'Datenschutz',
  components: {
    Menubar,
    UserMenu,
    ZuschauerMenu,
    SchiriMenu,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  created() {
    if (this.template.datenschutz) {
      if (this.template.datenschutz.link) {
        window.location.href = this.template.datenschutz.link
      }
    }
  },
}
</script>
